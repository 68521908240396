import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Netherlands",
  "subtitle": "May 2017",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <ul>
      <li parentName="ul">{`Local SIM - I picked one up at the airport which roamed on `}<a parentName="li" {...{
          "href": "https://mobile.lebara.com/nl/en/"
        }}>{`Lebara`}</a>{` (KPN Network). Data can be topped up online.`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Poffertjes"
        }}>{`Poffertjes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Pannenkoek"
        }}>{`Pannenkoek`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/French_fries"
        }}>{`Patatzak`}</a>{` - Dutch-style fries served with `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Fritessaus"
        }}>{`fritessaus`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vPJmxwBVarkpB1bB9"
        }}>{`Kamphuisen Siroopwafelfabriek`}</a>{` (Gouda) - I didn't visit the factory but these are my stroopwafels`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/etGNgespUS9hpStw5"
        }}>{`Reypeaer`}</a>{` (Woerden) - Had a fantastic hour tasting cheese and wine but I believe the Amsterdam location closed?`}</li>
    </ul>
    <h2 {...{
      "id": "amsterdam"
    }}>{`Amsterdam`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/nREqjhjYLmaKHRJu7"
        }}>{`Puccini Chocolate`}</a>{` - Chocolate sold by weight`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/FypeHQ6ziVRvFCGN8"
        }}>{`The Pancake Bakery`}</a>{` - Pannenkoek portion was huge`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LweCNrXW6sUQBAJg7"
        }}>{`Vlaams Friteshuis Vleminckx`}</a>{` - Dutch-style fries!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1WiL2fa48T1jWiiH9"
        }}>{`New King`}</a>{` - Mandarin restaurant`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NH51Lkqp8nRgyj9q9"
        }}>{`Bocca Coffee`}</a></li>
    </ul>
    <h4 {...{
      "id": "coffeeshops"
    }}>{`Coffeeshops`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "hhttps://maps.app.goo.gl/1ibBMJdTTBxH1p2C6"
        }}>{`Original Dampkring Coffeeshop`}</a>{` - Lemon rainbow cake was very tasty`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/w8u2nyRhwKRPLAjd9"
        }}>{`The Rookies Coffeeshop`}</a>{` - Space cake was so-so`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3VY9Eo8Jqwc5yk3y6"
        }}>{`Greendayz`}</a>{` - Sells various goods and cannabis infused ice cream`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/kcfVHKi8j6KrNtN79"
        }}>{`Van Gogh Museum`}</a>{` - Get the e-ticket with a designated time slot. I spent about 2 hours viewing the permanent exhibition and an hour on the rotating one. The audio guide was extra but I didn't find it particularly helpful`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NZkBfHRoP5Aacq446"
        }}>{`Rijksmuseum`}</a>{` - Get the e-ticket but skip the audio guide (extra). I only spent around 4 hours here but it wasn’t nearly enough time to see everything. Nice collections but too much to see in a single visit.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/1jhAfGZyUJKjuwbE6"
        }}>{`Koninklijk Paleis Amsterdam`}</a>{` - Nice if you have time; audio guide included`}</li>
    </ul>
    <h4 {...{
      "id": "shopping"
    }}>{`Shopping`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.hema.nl/"
        }}>{`Hema`}</a>{` - Chain that's better than Target`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://sostrenegrene.com/"
        }}>{`Sostrene grenes`}</a>{` - Craft and DIY chain`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/3d6aYHN43QBBRb6RA"
        }}>{`By Popular Demand`}</a>{` - Variety gift shop but I think most things can be bought online`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LqSsiGDXHRc9JKLJ6"
        }}>{`The American Book Center`}</a></li>
    </ul>
    <h2 {...{
      "id": "volendam"
    }}>{`Volendam`}</h2>
    <ul>
      <li parentName="ul">{`Small town, easily walkable`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QNRQqTcG8YhtrssG6"
        }}>{`Volendam Marken Express`}</a>{` - Taditional Dutch outfit photoshoot makes a fun souvenir!`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/DgjWGqUvSFmbTaGv8"
        }}>{`Bistro d’Ouwe Helling`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zbeoX7JawTyCzo6G8"
        }}>{`Fish & Chips Volendam`}</a></li>
    </ul>
    <h2 {...{
      "id": "zaandam"
    }}>{`Zaandam`}</h2>
    <ul>
      <li parentName="ul">{`So many windmills!`}</li>
      <li parentName="ul">{`The area smelled like chocolate but was actually very limited in food options`}</li>
    </ul>
    <h2 {...{
      "id": "keukenhof"
    }}>{`Keukenhof`}</h2>
    <ul>
      <li parentName="ul">{`Nicknamed `}<a parentName="li" {...{
          "href": "https://keukenhof.nl/en/"
        }}>{`The Garden of Europe`}</a></li>
      <li parentName="ul">{`I almost skipped it, but did a stopover on my way to Rotterdam and loved it. It's only opened from March through May with lots and lots of tulips to see!`}</li>
      <li parentName="ul">{`Best to arrive when it first opens because it starts to get really busy around noon`}</li>
    </ul>
    <h2 {...{
      "id": "rotterdam"
    }}>{`Rotterdam`}</h2>
    <ul>
      <li parentName="ul">{`This city was "different" in the sense that it's very modern compared to Amsterdam. However, there's lots of art and architecture gems everywhere.`}</li>
      <li parentName="ul">{`Lots of stores are closed on Mondays`}</li>
    </ul>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QrzW3jypm5hpHg5r6"
        }}>{`De Ijssalon`}</a>{` - Get a double scoop with stroopwafel and kinderbueno!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7PGsmLxHKT9HJWTS8"
        }}>{`Fenix Food Factory`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/npRJ7D9AJf1aFYBR8"
        }}>{`Kijk-Kubus`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Watch your head cause it’s awkwardly shaped inside`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/E5QXPdWCc4RGZFwo9"
            }}>{`Schaakstukkenmuseum`}</a>{` - Small chess museum`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/K1vfKJ4KKHXCNpna6"
        }}>{`Hotel New York`}</a>{` - More so the `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Hotel_New_York_(Rotterdam)"
        }}>{`history`}</a>{` since the ships used to depart to America from here`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/XwowjMJaFbaTfN6E7"
        }}>{`KPN Tower`}</a>{` - 96.5m tall with a very interesting design`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/fvD9DMzKg2EREJxt9"
        }}>{`Markthal`}</a>{` - Huge indoor market in an arch-shaped building with decorated ceilings. There's also an outdoor farmer's market.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/aXx4rQKQD3hzPirSA"
        }}>{`Erasmusbrug`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Erasmusbrug"
        }}>{`wiki`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2SvicMD1X6ZiCEcM7"
        }}>{`Depot Boijmans Van Beuningen`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ndtfo1RqhXLg6krZ9"
        }}>{`Binnenwegplein`}</a>{` - Shopping`}</li>
    </ul>
    <h2 {...{
      "id": "delft"
    }}>{`Delft`}</h2>
    <ul>
      <li parentName="ul">{`Very small town so bring cash`}</li>
      <li parentName="ul">{`Lots of stores are closed on Mondays`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/FPWNnhHfLyotMpps5"
        }}>{`Royal Delft`}</a>{` - Earthenware museum full of history on how imported Chinese porcelain inspired the blue pottery, Delftware. There were people working in the factory but you can still tour it. The short films weren’t that great. The gift shop was as big as the museum. About a 15 min walk from the train station`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YPuWyBDpf2i3wXVg7"
        }}>{`Nieuwe Kerk`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/FQDwGkhSDx7f3awZ7"
        }}>{`Oude Kerk`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      